import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import a from "./videos/Kimono22.webm";
import b from "./videos/Kimono27.webm";
import c from "./videos/Kimono32.webm";
import d from "./videos/Kimono37.webm";
import e from "./videos/Park22.webm";
import f from "./videos/Park27.webm";
import g from "./videos/Park32.webm";
import h from "./videos/Park37.webm";
const video1 = [a, b, c, d];
const video2 = [e, f, g, h];
const vids = [video1, video2];
export default vids;
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
