import Card from "../ui/Card";
import classes from "./ThankYou.module.css";

function ThankYou() {
    localStorage.setItem('flag',1)
  return (
    <Card>
      <h2 className={classes.thankyou}>Dziękujemy za udział w badaniu!</h2>
    </Card>
  );
}
export default ThankYou;
