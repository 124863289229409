import { Route, Switch } from "react-router-dom";
import MainPage from "./Pages/MainPage";
import FormPage from "./Pages/FormPage";
import RatingPage from "./Pages/RatingPage";
import ThankYouPage from "./Pages/ThankYouPage";
import TopBar from "./components/TopBar/TopBar";
import Footer from "./components/Footer/Footer";
import Container from "./components/ui/Container";

function App() {
  localStorage.setItem("index1", 0);
  localStorage.setItem("index2", 0);
  localStorage.setItem("iterator", 0);

  return (
    <div className="maincontainer">
      <Container>
        <TopBar />
        <Switch>
          <Route path="/" exact>
            <MainPage />
          </Route>

          <Route path="/form">
            <FormPage />
          </Route>

          <Route path="/rating">
            <RatingPage />
          </Route>

          <Route path="/thank-you">
            <ThankYouPage />
          </Route>
        </Switch>
        <Footer />
      </Container>
    </div>
  );
}

export default App;
