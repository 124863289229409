import classes from "./Container.module.css";
function Container(props) {
  return (
    
      <div className={classes.Container}>{props.children}</div>
     
   
  );
}
export default Container;
