import classes from "./Card.module.css";
function Card(props) {
  return (
    <div className={classes.CardContainer}>
      <div className={classes.Card}>{props.children}</div>
     
    </div>
  );
}
export default Card;
