import classes from "./MainPageBlock.module.css";
import { Link } from "react-router-dom";
import Card from "../ui/Card";
function MainPageBlock() {
  return (
    <Card>
      <div className={classes.cardstyle}>
        <div className={classes.bold}> Drogi użytkowniku!</div> <br />
        Znajdujesz się na stronie służącej do subiektywnej oceny jakości
        sekwencji wizyjnych. Twoim zadaniem jako osoby badanej będzie udział w
        teście polegającym na wypełnieniu krótkiego formularza oraz obejrzeniu i
        ocenieniu jakości kilku wybranych filmów. Zebrane dane zostaną opisane w
        pracy dyplomowej.
      </div>
      <Link to="/form">
        <button className={classes.button}>Dalej</button>
      </Link>
    </Card>
  );
}
export default MainPageBlock;
