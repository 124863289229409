import classes from "./Footer.module.css";
import logo from "./logo.svg";
function Footer() {
  return (
    <footer className={classes.footer}>
      <img className={classes.photo} src={logo} alt="Logo" />
      <div className={classes.serverinfo}>
        Serwis znajduje się na serwerze Politechniki Poznańskiej
        <br /> Osoba odpowiedzialna za treść: <br />
        <a
          className={classes.email}
          href="mailto:rafal.hadzicki@student.put.poznan.pl"
        >
          Rafał Hadzicki
        </a>
      </div>
    </footer>
  );
}
export default Footer;
