import SubBar from "../components/TopBar/SubBar";
import Card from "../components/ui/Card";
import Video from "../components/Video/Video";
import vids from "..";
import React, { Suspense, useState } from "react";
//import Rating from "../components/Rating/Rating";
import classes from "./RatingPage.module.css";
import LoadingSpinner from "../components/ui/Spinner";
import screenfull from "screenfull";
const Rating = React.lazy(() => import("../components/Rating/Rating"));
function RatingPage() {
  let iterator = localStorage.getItem("iterator");
  let deviceSupportedFlag;
  if (screenfull.isEnabled) {
    deviceSupportedFlag = true;
  } else {
    deviceSupportedFlag = false;
  }

  function drawIndex() {
    //funkcja do losowania sekwencji
    let number1 = Math.floor(Math.random() * (vids[iterator].length - 1 + 1)); //max+1 - wylosuj numer między 0 a max
    let number2 = Math.floor(Math.random() * (vids[iterator].length - 1 + 1));

    while (number1 === number2) {
      number2 = Math.floor(Math.random() * (vids[iterator].length - 1 + 1));
    }
    //console.log(number1, number2, vids[iterator].length);
    localStorage.setItem("index1", number1);
    localStorage.setItem("index2", number2);
  }
  const [isPreloaded, preload] = useState(true); //useState do wskazywania czy film został załadowany

  const [showVideos, setVideos] = useState(false); //use state do wyświetlania odtwarzacza
  function VideosHandler() {
    setVideos(true);
  }
  const [isButtonVisible, setButtonVisibility] = useState(true); //use state do "znikania" przycisku start
  function buttonVisibilityHandler() {
    setButtonVisibility(false);
  }
  function revealVideos() {
    //funkcja wyświetla sekwencje i ukrywa przycisk start

    drawIndex(); //wylosuj indeksy
    VideosHandler(); //wyświetl odtwarzacz
    buttonVisibilityHandler(); //ukryj przycisk
    preload(false); //zacznij ładować filmy
  }

  const [videoSequence, setVideoSequence] = useState(vids[iterator]); //use state do zmiany wyświetlanych filmów na inne
  function videoSequenceHandler() {
    setVideoSequence(vids[++iterator]);
    drawIndex();
    localStorage.setItem("iterator", iterator);
    videoEndHandler(false);
  }
  const [didVideoEnd, setVideoEnd] = useState(false); //use state do obsługi przycisku "następna sekwencja" ustawiony na true wyświetla przycisk
  function videoEndHandler(trueorfalse) {
    setVideoEnd(trueorfalse);
  }

  return (
    <div>
      <SubBar text="Ocena jakości sekwencji" />

      {deviceSupportedFlag && (
        <Card>
          {
            isButtonVisible && (
              <div className={classes.startContainer}>
                <div className={classes.start}>
                  <div className={classes.guide}>Instrukcja: </div>
                  <br />
                  Test polega na obejrzeniu pary tych samych filmów, a następnie
                  subiektywnej ocenie ich jakości. Po kliknięciu poniższego
                  przycisku rozpocznie się ładowanie sekwencji podlegających
                  ocenie. Niektóre pliki z filmem zajmują nawet 500 MB pamięci -
                  postęp ładowania jest widoczny na ekranie. Przycisk
                  odtwarzania zostanie wyświetlony po pełnym załadowaniu
                  sekwencji. <b>Każdy film można obejrzeć tylko raz.</b> Po
                  rozpoczęciu odtwarzania strona przejdzie w tryb pełnoekranowy.
                  Strona nie obsługuje urządzeń z systemem iOS.
                </div>
                <button className={classes.button} onClick={revealVideos}>
                  Start
                </button>
              </div>
            ) /*jezeli isbuttonvisible==true renderuje przycisk*/
          }

          {
            showVideos && (
              <Video
                videos={videoSequence}
                indeks1={localStorage.getItem("index1")}
                indeks2={localStorage.getItem("index2")}
                videoHandler={setVideoEnd}
                showHandler={setVideos}
                preloadFlag={isPreloaded}
                preloadActivate={preload}
              />
            ) /*jezeli showVideos==true renderuj odtwarzacz*/
          }
        </Card>
      )}
      {!deviceSupportedFlag && (
        <Card>
          <div className={classes.message}>Urządzenie nie jest wspierane</div>{" "}
        </Card>
      )}
      {didVideoEnd && ( //jeżeli film ostatni został obejrzany renderuj komponent do głosowania
        <Suspense
          fallback={
            <div className={classes.spinner}>
              <LoadingSpinner />
            </div>
          }
        >
          <Card>
            <Rating
              sequence={iterator}
              VideoEnd={didVideoEnd}
              sequenceHandler={videoSequenceHandler}
              showHandler={setVideos}
              preloadActivate={preload}
            />
          </Card>
        </Suspense>
      )}
    </div>
  );
}
export default RatingPage;
