import ReactPlayer from "react-player";
import classes from "./Video.module.css";
import { useState } from "react";
import { useRef } from "react";
import screenfull from "screenfull";
import ProgressBar from "./ProgressBar";
import placeholder from "./loading_circle_bars.mp4";
import PlayButton from "./PlayButton";

function Video(props) {
  const player1 = useRef();
  const player2 = useRef();
  const [videoFlag, setVideoFlag] = useState(false); //flaga sygnalizująca obejrzenie pierwszego filmu
  function videoFlagHandler() {
    setVideoFlag(true);
    closeFullScreen1();

    //props.revealHandler(true)
    //screenfull.request(player2.current.wrapper);
  }

  const [playFlag, setPlayFlag] = useState(false); //flaga sygnalizująca odtwarzanie filmu nr 1
  function playFlagHandler() {
    setPlayFlag(false);
  }
  const [playFlag2, setPlayFlag2] = useState(false); //flaga sygnalizująca odtwarzanie filmu nr 2
  function playFlagHandler2() {
    setPlayFlag2(false);
  }
  if (screenfull.isEnabled) {
    screenfull.on("change", () => {
      //listener do sprawdzania czy uytkownik zamknął pełny ekran
      if (screenfull.isFullscreen === false) {
        playFlagHandler();
        playFlagHandler2();
      }
    });
  }

  function onClickFullscreen1() {
    if (screenfull.isEnabled) {
      screenfull.request(player1.current.wrapper);
      setPlayFlag(true);
    }
  }
  function onClickFullscreen2() {
    if (screenfull.isEnabled) {
      screenfull.request(player2.current.wrapper);
      setPlayFlag2(true);
    }
  }
  function closeFullScreen1() {
    if (screenfull.isEnabled) {
      screenfull.exit();
    }
  }
  function closeFullScreen2() {
    if (screenfull.isEnabled) {
      setVideoFlag(false);
      screenfull.exit();
      props.videoHandler(true);
      //props.revealHandler(true)
      setTimeout(() => {
        props.showHandler(false);
      }, 500);
    }
  }
  const [progress1, setProgress1] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [video1LoadingFlag, setVideo1LoadingFlag] = useState(true);
  const [video2LoadingFlag, setVideo2LoadingFlag] = useState(true);
  function onSuccess(blob_url, url) {
    // console.log(blob_url, url);
    if (url === props.videos[props.indeks1]) {
      changehandler(blob_url);
      setVideo1LoadingFlag(false);
    } else {
      changehandler2(blob_url);
      setVideo2LoadingFlag(false);
    }
  }

  function onProgress(pc, url) {
    if (url === props.videos[props.indeks1]) {
      setProgress1(pc);
      // console.log("video 1 progress: ", pc);
    } else {
      setProgress2(pc);
      //console.log("video 2 progress: ", pc);
    }
  }

  function onError() {
    console.log("error during file fetching!!");
  }

  function prefetch_file(
    url,
    fetched_callback,
    progress_callback,
    error_callback
  ) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";

    xhr.addEventListener(
      "load",
      function () {
        if (xhr.status === 200) {
          var URL = window.URL || window.webkitURL;
          var blob_url = URL.createObjectURL(xhr.response);
          fetched_callback(blob_url, url);
        } else {
          error_callback();
        }
      },
      false
    );

    var prev_pc = 0;
    xhr.addEventListener("progress", function (event) {
      if (event.lengthComputable) {
        var pc = Math.round((event.loaded / event.total) * 100);
        if (pc !== prev_pc) {
          prev_pc = pc;
          progress_callback(pc, url);
        }
      }
    });
    xhr.send();
  }

  const [url1, changeUrl] = useState(placeholder);
  const [url2, changeUrl2] = useState(placeholder);
  const changehandler = (url) => {
    changeUrl(url);
  };
  const changehandler2 = (url) => {
    changeUrl2(url);
  };
  const preLoad = () => {
    if (props.preloadFlag === false) {
      props.preloadActivate(true);

      prefetch_file(
        props.videos[props.indeks1],
        onSuccess,
        onProgress,
        onError
      );
      prefetch_file(
        props.videos[props.indeks2],
        onSuccess,
        onProgress,
        onError
      );
    }
  };

  return (
    <div className={classes.main__container}>
      <div className={classes.player__container}>
        <div className={classes.video__label}>Sekwencja 1</div>
        <div className={classes.player__container2}>
          <ReactPlayer
            onReady={preLoad}
            ref={player1}
            url={url1}
            playing={playFlag} //flaga czy film będzie odtwarzany na podstawie fullscreena
            width="100%"
            height="300px"
            controls={false}
            onEnded={videoFlagHandler} //na koniec odtwarzania uruchom handler uruchamiający controls w drugim filmie
            // onPlay={onClickFullscreen1}
          />
          {video1LoadingFlag && <ProgressBar progress={progress1} />}
          {!videoFlag && !video1LoadingFlag && (
            <PlayButton onClick={onClickFullscreen1} />
          )}
        </div>
      </div>
      <div className={classes.player__container}>
        <div className={classes.video__label}>Sekwencja 2</div>
        <div className={classes.player__container2}>
          <ReactPlayer
            ref={player2}
            //className={classes.player}
            url={url2}
            width="100%"
            height="300px"
            //progressInterval={500}
            controls={false}
            playing={playFlag2}
            //onProgress={(d) => handleProgress2(d.loaded, d.loadedSeconds)}
            //onPlay={onClickFullscreen2}
            onEnded={closeFullScreen2}
          />
          {video2LoadingFlag && <ProgressBar progress={progress2} />}
          {!video2LoadingFlag && videoFlag && (
            <PlayButton onClick={onClickFullscreen2} />
          )}
        </div>
      </div>
    </div>
  );
}
export default Video;
