import SubBar from "../components/TopBar/SubBar";
import Form from "../components/Form/Form";

function FormPage() {
  return (
    <div >
      <SubBar text="Formularz" />
      <Form/>
    </div>
  );
}
export default FormPage;
