import ThankYou from "../components/ThankYou/ThankYou";
import SubBar from "../components/TopBar/SubBar";

function ThankYouPage() {
  return (
    <div>
      <SubBar text="Dziękujemy" />
      <ThankYou />
    </div>
  );
}
export default ThankYouPage;
