import React from "react";
import classes from "./PlayButton.module.css";

const PlayButton = (props) => {
  return (
    <div className={classes.buttonContainer}>
      <button className={classes.button} onClick={props.onClick}></button>
    </div>
  );
};

export default PlayButton;
