import React from "react";
import classes from "./ProgressBar.module.css";
const ProgressBar = (props) => {
  return (
    <div className={classes.loaderContainer}>
      <div>
        <div className={classes.progressText}>{props.progress + "%"}</div>
      </div>
    </div>
  );
};

export default ProgressBar;
