import { useState } from "react";
import Card from "../ui/Card";
import classes from "./Form.module.css";
import { useHistory } from "react-router-dom";
import { stringify } from "json5";
function Form() {
  const [pickedGender, setGender] = useState("Kobieta");
  const [pickedAge, setAge] = useState(">21");
  let formData;
  const dimensions = () => {
    const width = window.screen.width;
    const height = window.screen.height;

    const resolution = stringify(width) + "x" + stringify(height);
    return resolution;
  };
  function genderChangeHandler() {
    setGender("Kobieta");
  }
  function genderChangeHandler2() {
    setGender("Mezczyzna");
  }
  function ageChangeHandler() {
    setAge("<21");
  }
  function ageChangeHandler2() {
    setAge("21-30");
  }
  function ageChangeHandler3() {
    setAge("31-40");
  }
  function ageChangeHandler4() {
    setAge("41-50");
  }
  function ageChangeHandler5() {
    setAge(">50");
  }
  let history = useHistory();
  function submitHandler(event) {
    event.preventDefault();
    formData = {
      gender: pickedGender,
      age_range: pickedAge,
      screen_resolution: dimensions(),
    };
    localStorage.setItem("formData", JSON.stringify(formData));

    history.push("/rating");
  }

  return (
    <Card>
      <form className={classes.form} onSubmit={submitHandler}>
        <div className={classes.form__header}>Prosimy o podanie danych:</div>
        <div className={classes.form__element}>Płeć:</div>
        <div className={classes.form__option}>
          <input
            type="radio"
            name="gender"
            value="kobieta"
            required
            onChange={genderChangeHandler}
            id="Kobieta"
          />
          <label htmlFor="Kobieta">Kobieta </label>
        </div>
        <div className={classes.form__option}>
          <input
            type="radio"
            name="gender"
            value="męzczyzna"
            required
            onChange={genderChangeHandler2}
            id="Mężczyzna"
          />
          <label htmlFor="Mężczyzna">Mężczyzna </label>
        </div>
        <div className={classes.form__element}>Przedział wiekowy:</div>
        <div className={classes.form__option}>
          <input
            type="radio"
            name="age"
            value="< 21"
            required
            onChange={ageChangeHandler}
            id="21"
          />
          <label htmlFor="21">{"< 21 lat"} </label>
        </div>

        <div className={classes.form__option}>
          <input
            type="radio"
            name="age"
            value="21-30"
            required
            onChange={ageChangeHandler2}
            id="30"
          />
          <label htmlFor="30">{"21-30 lat"} </label>
        </div>
        <div className={classes.form__option}>
          <input
            type="radio"
            name="age"
            value="31-40"
            required
            onChange={ageChangeHandler3}
            id="40"
          />
          <label htmlFor="40">{"31-40 lat"} </label>
        </div>
        <div className={classes.form__option}>
          <input
            type="radio"
            name="age"
            value="41-50"
            required
            onChange={ageChangeHandler4}
            id="50"
          />
          <label htmlFor="50">{"41-50 lat"} </label>
        </div>
        <div className={classes.form__option}>
          <input
            type="radio"
            name="age"
            value="> 50"
            required
            onChange={ageChangeHandler5}
            id=">50"
          />
          <label htmlFor=">50">{"> 50"} </label>
        </div>

        <button className={classes.form__button}>Dalej</button>
      </form>
    </Card>
  );
}
export default Form;
