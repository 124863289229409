
import classes from './TopBar.module.css'
function TopBar(props) {
  return (
    <header className={classes.header}>
      <div className={classes.logo}>Videoquality</div>
    </header>
  );
}
export default TopBar;
