import SubBar from "../components/TopBar/SubBar";
import MainPageBlock from "../components/MainPageBlock/MainPageBlock";

function MainPage() {
  return (
    <div>
      <SubBar text="Strona główna" />
      <MainPageBlock />
    </div>
  );
}
export default MainPage;
